import React from 'react'
import { Link } from 'gatsby'

import SecondaryLogo from '../../images/svgs/arcipluvia-logo-secondary.svg'
import DividerImage from '../../images/divider.png'
import CDOLogo from '../../images/svgs/cdo-logo.svg'

export default function Footer() {

	return (
		<footer className="footer" style={{ backgroundImage: `url(${DividerImage})` }}>
			<div className="footer__wrapper wrapper">
				<div className="footer__content-container footer__content-container--logo">
					<SecondaryLogo className="footer__logo" />
				</div>
				<div className="footer__content-container">
					<ul className="footer__list">
						<li className="footer__list-item">
							Arcipluvia
						</li>
						<li className="footer__list-item">
							Email: <a href="mailto:hello@arcipluvia.co.uk" className="footer__link">hello@arcipluvia.co.uk</a>
						</li>
						<li className="footer__list-item">
							Instagram: <a href="https://www.instagram.com/arcipluvia/?hl=en" className="footer__link">@arcipluvia</a>
						</li>
					</ul>
				</div>
				<div className="footer__content-container">
					<ul className="footer__list">
						<li className="footer__list-item">
							<Link to="/privacy-policy/" className="footer__link">Cookie &amp; Privacy Policy</Link>
						</li>
						<li className="footer__list-item">
							<Link to="/delivery-and-returns/" className="footer__link">Delivery &amp; Returns</Link>
						</li>
						<li className="footer__list-item">
							<Link to="/terms-and-conditions/" className="footer__link">Terms &amp; Conditions</Link>
						</li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__meta-text-container">
						<div className="footer__meta-text">Developed by:</div>
					</div>
					<a href="https://www.cdodigital.com" className="footer__meta-logo-link" target="_blank" rel="noreferrer" aria-label="CDO Digital Website">
						<CDOLogo className="footer__meta-logo" />
					</a>
				</div>
			</div>
			<div className="footer__copyright">Copyright { new Date().getFullYear() }. Arcipluvia.</div>
		</footer>
	)
}
