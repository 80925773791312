import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'gatsby'

import Navigation from '../Navigation/Navigation'
import CartHeader from '../Navigation/Cart'

import ArcipluviaLogo from '../../images/svgs/arcipluvia-logo.svg'
import CartIcon from '../../images/svgs/cart-icon.svg'

import { CartContext } from '../../context/CartContext'

import { MEASUREMENTS } from '../../helpers/constants'

export default function Header () {

	const RESIZE_DEBOUNCE = 150;
	const headerRef = useRef()

	const getWidth = () => headerRef.current.offsetWidth;

	const { cart } = useContext( CartContext );
	const [ cartVisible, showCart ] = useState(false);
	const [ menuToggled, toggleMenu ] = useState(false)
	const [ width, setWidth ] = useState( 0 )

	useEffect( () => {
		if ( headerRef ) {
			setWidth( headerRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE );
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};
	}, [] );

	const onHover = ( trigger ) => {
		if ( window.innerWidth > MEASUREMENTS.TABLET ) {
			trigger( true )
		}
	}

	const handleClick = () => {

		// if ( !menuToggled ) {
		// 	const newScrollPosition = window.pageYOffset

		// 	document.body.style.top = `-${newScrollPosition}px`
		// 	document.body.classList.add('u-body-no-scroll')
		// } else {
		// 	const newScrollPosition = document.body.getBoundingClientRect().top

		// 	document.body.classList.remove('u-body-no-scroll');
		// 	document.body.style.removeProperty('top')

		// 	window.scrollTo(0, Math.abs( newScrollPosition ))
		// }

		toggleMenu( !menuToggled )
	}


	return (
		<header className="header" ref={ headerRef }>

			<div className="header__wrapper">
				<div className="header__container">

					<button className="header__mobile-menu-toggle" onClick={ () =>handleClick() }>
						<div className={`hamburger ${ menuToggled ? 'active' : '' }`}>
							<span className="hamburger__box">
								<span className="hamburger__inner"></span>
							</span>
						</div>
					</button>

					<Link to="/" className="header__title-link" aria-label="Homepage">
						<ArcipluviaLogo className="header__logo" />
					</Link>

					<div className="header__navigation-container">
						<Navigation toggleMenu={ toggleMenu } menuToggled={ menuToggled } width={ width } />

						<div className={`navigation__meta ${ cart && cart.length ? 'active' : '' }`}>

							<button
								className="navigation__cart-container"
								onClick={() => showCart( !cartVisible )}
								onMouseEnter={ () => onHover( showCart ) }
								onMouseLeave={() => showCart( false )}
								tabIndex={0}
								aria-label="View items in your Art Cart" >
								<CartIcon className="navigation__cart-icon"/>
								{ cart && cart.length > 0 &&
									<span className="navigation__cart-quantity" >
										{ cart.reduce( ( counter, product ) => {
										return counter + product.quantity;
										}, 0 ) }
									</span>
								}
							</button>

							<CartHeader isOpen={ cartVisible } />
						</div>
					</div>

				</div>
			</div>
		</header>
	)
}
