export const saveCart = ( cart ) => {
	localStorage.setItem( 'cart', JSON.stringify( cart ) );
};

export const getCart = () => {

	if ( typeof window === 'undefined' ) {
		return [];
	}

	const cart = JSON.parse( localStorage.getItem( 'cart' ) ) ? JSON.parse( localStorage.getItem( 'cart' ) ) : [];

	return cart;
};

export const saveAppliedOffer = ( offer ) => {
	localStorage.setItem( 'offer', JSON.stringify( offer ) );
};

export const getAppliedOffer = () => {

	if ( typeof window === 'undefined' ) {
		return [];
	}

	const AppliedOffer = JSON.parse( localStorage.getItem( 'offer' ) ) ? JSON.parse( localStorage.getItem( 'offer' ) ) : [];
	
	return AppliedOffer;
};

export const cartSubTotal = ( cart, offers = [] ) => {

	const productCounter = countProducts( cart )
	
	// check if the products have any offers
	const subTotal = productCounter.reduce( ( counter, node ) => {
		const newSubTotal = calulateOfferPrice( node, offers )
		return counter + newSubTotal
	}, 0)

	return Math.round( subTotal * 100 ) / 100;
}

export const countProducts = ( cart = [] ) => {

	// create a product counter to see how many Cards, prints etc in the cart
	const productCounter = [] 

	cart.forEach( product => { 
		const foundIndex = productCounter.findIndex( node => node.type === product.category.name )
		if( foundIndex !== -1 ) {
			productCounter[ foundIndex ].quantity += product.quantity
			productCounter[ foundIndex ].subTotal += product.price * product.quantity
		} else {
			productCounter.push( { quantity: product.quantity, subTotal: product.price * product.quantity, type: product.category.name } )
		}
	} );

	return productCounter
}

/**
 * takes in a productCount which has attributes: quantity, subtotal, type 
 * then returns the best offer for this type
 * @param { Object } productCount 
 * @param { Object } offers 
 * @returns { Object } best offer
 */
export const findBestOffer = ( productCount, offers ) => {

	const foundOffers = offers.find( offer => offer.category.name === productCount.type )
	let bestOffer;

	if( foundOffers ) {
		let minimumQuantityRequired = 0;
		// to find best discount 
		foundOffers.discounts.forEach( node => {
			if( node.quantity > minimumQuantityRequired && productCount.quantity >= node.quantity ){
				minimumQuantityRequired = node.quantity
				//  adding the category name to the offer
				bestOffer = { ...node, categoryName: productCount.type }
			}
		})
	}

	return bestOffer
}

const calulateOfferPrice = ( type, offers ) => {
	// find offer for that type

	const bestOffer = findBestOffer( type, offers )

	if( bestOffer ) {
		if( bestOffer.type === "percentage" ) {
			return  Math.round( ( 1 - ( bestOffer.discount / 100 )) * type.subTotal * 100 ) / 100;
		} else {
			return  Math.round( bestOffer.discount * type.quantity * 100 ) / 100;
		}
	} else {
		return type.subTotal
	}
}

export const cartTotal = ( cart, postage, offers = [] ) => {
	if ( cart.length === 0 ) {
		return 0;
	}

	const subTotal = cartSubTotal( cart, offers );
	const shipping = postage.price;
	const total = subTotal + shipping;

	return Math.round( total * 100 ) / 100;
}
