import React from 'react'
import { ApolloProvider } from '@apollo/client';
import { client } from './client';

import CartContextProvider from './src/context/CartContext'

import CookiePolicy from './src/components/Cookie/CookiePolicy'
import Layout from './src/components/Layout/Layout'
import Sidebar from './src/components/Sidebar/Sidebar'

import './src/styles/toolkit.scss'

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={ client }>
		<CartContextProvider>
			<Layout>
				{ element }
				<CookiePolicy />
				{/* <Sidebar /> */}
			</Layout>
		</CartContextProvider>
	</ApolloProvider>

)