import React, { createContext, useState } from 'react';

import { getCart, saveCart, findBestOffer, countProducts, getAppliedOffer, saveAppliedOffer } from '../helpers/cart'
import { GTRemoveFromCart } from '../helpers/gtagEvents'

export const CartContext = createContext( null );

const CartContextWrapper = ( { children } ) => {
	const [ cart, setCart ] = useState( getCart() );
	const [ postage, setPostage ] = useState({ price: 0 });
	const [ offers, setOffers ] = useState();
	const [ hasOfferApplied, setHasOfferApplied ] = useState( getAppliedOffer() );
	const [ triggerCartEvent, setTriggerCartEvent ] = useState( false )
	const updateCart = ( updatedCart ) => {
		setCart( updatedCart );
		saveCart( updatedCart );
	}

	const addToCart = ( product, quantity = 1, show = true ) => {
		// Get initial state of cart
		const cartCopy = [ ...cart ]
		product.id = parseInt( product.id ) // Forces it to be an integer
		const indexOfProduct = cartCopy.findIndex( alreadyInCart => parseInt( alreadyInCart.id ) === parseInt( product.id ) && product.framed === alreadyInCart.framed );
		// works out current quantity in bag of id = x
		const currentQuantity = cartCopy ? cartCopy.reduce( ( counter, alreadyInCart ) => ( parseInt( alreadyInCart.id ) === parseInt( product.id ) ? counter + alreadyInCart.quantity : counter ) , 0) : 0

		if ( indexOfProduct !== -1 ) {
			// works out how many of the other varaient eg "framed" items in the bag
			const quantityOfOtherProduct = currentQuantity - cartCopy[ indexOfProduct ].quantity
			// Update the quantity
			cartCopy[ indexOfProduct ].quantity += parseInt( quantity );

			if ( currentQuantity + parseInt( quantity ) >= cartCopy[ indexOfProduct ].quantityInStock ) {
				cartCopy[ indexOfProduct ].quantity = cartCopy[ indexOfProduct ].quantityInStock - quantityOfOtherProduct;
			}
	
			if ( cartCopy[ indexOfProduct ].quantity === 0 ) {
				// Remove item from cart when quantity is 0
				cartCopy.splice( indexOfProduct, 1 );
			}
	
		} else {
			if( currentQuantity + quantity > product.quantityInStock ){
				product.quantity = product.quantityInStock - currentQuantity;
			} else {
				product.quantity = quantity
			}

			// edge case when someone is adding a framed photo when already having all the unframed stock in bag
			if( product.quantity !== 0 ){
				// Push the product to the cart
				cartCopy.push( product );
			}
		}

		findDiscount( cartCopy )

		updateCart( cartCopy );
		setTriggerCartEvent( show )

		setTimeout(() => {
			setTriggerCartEvent( false )
		}, 2000)
	}

	const removeFromCart = product => {
		// Get initial state of cart
		const cartCopy = [ ...cart ]

		const indexOfProduct = cartCopy.findIndex( alreadyInCart => parseInt( alreadyInCart.id ) === parseInt( product.id ) && product.framed === alreadyInCart.framed )
		const productToRemove = cartCopy[ indexOfProduct ]

		cartCopy.splice( indexOfProduct, 1 )
		findDiscount( cartCopy )
		updateCart( cartCopy )
		GTRemoveFromCart( productToRemove ) // Trigger gtag event
	}

	const clearCart = () => {
		const emptyCart = [];
		updateCart( emptyCart );
		findDiscount( emptyCart )
	}

	const findDiscount = ( cart ) => {
		const productCount = countProducts( cart )
		
		const offersApplied = productCount.map( count => {
			const bestOffer = findBestOffer( count, offers )

			return bestOffer
		})

		let aOfferHasBeenApplied = []

		offersApplied.forEach( offer => {
			if( offer ){
				cart.forEach( product => {
					if( product.category.name === offer.categoryName ){
						if( offer.type === "percentage" ) {
							product.offerPrice = Math.round( ( 1 - ( offer.discount / 100 )) * product.price * 100 ) / 100;
						} else {
							product.offerPrice = offer.discount
						}
						
					}
				})
				aOfferHasBeenApplied.push( { offer: true, name: offer.categoryName } )
			}
		});

		saveAppliedOffer( aOfferHasBeenApplied )
		setHasOfferApplied( aOfferHasBeenApplied )

		return aOfferHasBeenApplied
	}

	return (
		// shippingState, setShippingState,
		<CartContext.Provider value={{ cart, postage, setPostage, addToCart, removeFromCart, clearCart, triggerCartEvent, setOffers, offers, hasOfferApplied }}>
			{ children }
		</CartContext.Provider>
	)
}

export default CartContextWrapper;