import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { CartContext } from '../../context/CartContext'

import { cartSubTotal } from '../../helpers/cart'
import { formatPrice } from '../../helpers/currency'

import CartIcon from '../../images/svgs/cart-icon.svg'
import CloseIcon from '../../images/svgs/close-icon.svg'

export default function CartHeader( props ) {

	const isInitialMount = useRef( true )
	const { cart, removeFromCart, triggerCartEvent, offers, hasOfferApplied } = useContext( CartContext )
	const [ cartVisible, showCart ] = useState(false)
	const [ active, setActive ] = useState(false)

	useEffect(() => {
		if ( isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			if ( triggerCartEvent || active ) {
				showCart( true )
			} else {
				showCart( false )
			}
		}
	}, [ triggerCartEvent, active ])

	const handleShowCart = ( isCartVisible ) => {
		setActive( isCartVisible )
		showCart( isCartVisible )
	}

	return (
		<div
			className={ `cart-header ${ props.isOpen || cartVisible ? 'open' : '' }` }
			onMouseOver={() => handleShowCart( true )}
			onFocus={() => handleShowCart( true )}
			onMouseLeave={() => handleShowCart( false )}
			role="menuitem"
			tabIndex={0}
			ref={ isInitialMount }>
			{ cart && cart.length > 0 ? (
				<>
					<div className="cart-header__header">
						Your Art Cart ({ cart.length } items)
					</div>
					<div className="cart-header__product-list">
						{ cart.map( ( product, index ) => {
							const productHasOffer = hasOfferApplied.some( offer => offer.name === product.category.name ) && product.offerPrice

							return (
								<div className="cart-header__product" key={ index }>
									<Link to={`/${ product.slug }/`} className="cart-header__product-link">
										<div className="cart-header__product-image-container">
											<GatsbyImage
												image={product.images[0].imageFile.childImageSharp.gatsbyImageData}
												className="cart-header__product-image"
												objectFit="contain"
												loading="lazy"
												alt={ product.name }
											/>
										</div>

										<div className="cart-header__product-meta">
											<span className="cart-header__product-name">{ product.name }</span>
											<span className="cart-header__product-quantity">Quantity: { product.quantity }</span>
											
											<div className="cart-header__price-container">
												<span className={`cart-header__product-price ${ productHasOffer ? 'offer' : '' }` } >
													{ formatPrice( product.price ) }
												</span>
												{ productHasOffer &&
													<span className="cart-header__product-price">{ formatPrice( product.offerPrice ) }</span>
												}
											</div>
										</div>
									</Link>
									<CloseIcon className="cart-header__product-remove" onClick={ () => removeFromCart( product ) } />
								</div>
						) } ) } 
					</div>
					<div className="cart-header__meta">
						<div className="cart-header__subtotal">Subtotal: <span className="cart-header__subtotal-price">{ formatPrice( cartSubTotal( cart, offers ) ) }</span></div>
						<div className="cart-header__button-container">
							<Link to="/cart/" className="cart-header__button" onClick={() => handleShowCart(false)}>
								<CartIcon className="cart-header__button-icon"/>
								View Your Art Cart
							</Link>
						</div>
					</div>
				</>
			) : (
				<div className="cart-header__header">
					Your Art Cart (0 items)
				</div>
			) }
		</div>
	)
}

CartHeader.propTypes = {
	isOpen: PropTypes.bool,
}
