import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import { CartContext } from '../../context/CartContext'

import Header from '../Header/Header'
import Footer from "../Footer/Footer"

const Layout = ({ children }) => {

	const { setOffers } = useContext( CartContext );

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			strapi {
				offer {
					offer {
						id
						discounts {
							discount
							id
							quantity
							type
						}
						category {
							name
						}
					}
				}
			}
		}
	`)

	useEffect( () => {
		if ( data.strapi.offer ) {
			setOffers( data.strapi.offer.offer )
		}
	}, [ data.strapi.offer, setOffers ])

	return (
		<>
			<Header />
			<main className="main-content">
				<div className="body-content">
					{children}
					<Footer />
				</div>
			</main>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout