/**
 * Formats an integer price into a price with a preceeding £ sign
 * @params {String} - priceWithDecimals integer respresentation of price
 */
export const formatPrice = ( priceWithDecimals ) => {

	if ( !priceWithDecimals ) {
		return 0;
	}

	const realPrice = parseFloat( priceWithDecimals );
	return realPrice.toLocaleString( 'en-GB', {
		style: 'currency',
		currency: 'GBP'
	} )
}